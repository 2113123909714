export const language = [
  { key: "en", label: "EN" },
  { key: "ru", label: "RU" },
  { key: "uz", label: "UZ" },
]

export const permission = [
  {
    key: "/admin/category/index",
    id: 1,
    name: " index",
  },
  {
    key: "/admin/category/create",
    id: 2,
    name: "create",
  },
  {
    key: "/admin/category/update",
    id: 3,
    name: "update",
  },
  {
    key: "/admin/category/remove",
    id: 3,
    name: "remove",
  },
  {
    key: "/admin/unit/index",
    id: 1,
    name: "index",
  },
  {
    key: "/admin/unit/create",
    id: 2,
    name: "create",
  },
  {
    key: "/admin/unit/update",
    id: 3,
    name: "update",
  },
  {
    key: "/admin/unit/remove",
    id: 3,
    name: "remove",
  },
  {
    key: "/admin/attribute/index",
    id: 1,
    name: "index",
  },
]

export const soliqApi = "https://v3.soliqservis.uz:3443/api"

export const committentKey = {
  disk: "/Volumes/DSKEYS/",
  path: "",
  name: "DS3102961330001",
  alias:
    "cn=xusanov saloxiddin to‘ychimurod o‘g‘li,name=saloxiddin,surname=xusanov,l=olmazor tumani,st=toshkent shahri,c=uz,o=media view mchj,uid=551865964,1.2.860.3.16.1.2=30411956780017,ou=кўрсатилмаган,t=direktor,1.2.860.3.16.1.1=310296133,businesscategory=masʼuliyati cheklangan jamiyat,serialnumber=77f153c0,validfrom=2023.03.07 14:51:11,validto=2025.03.07 23:59:59",
  serialNumber: "77F153C0",
  validFrom: "2023-03-07T09:51:11.000Z",
  validTo: "2025-03-07T18:59:59.000Z",
  CN: "XUSANOV SALOXIDDIN TO‘YCHIMUROD O‘G‘LI",
  TIN: "310296133",
  UID: "551865964",
  PINFL: "30411956780017",
  O: "MEDIA VIEW MCHJ",
  T: "DIREKTOR",
  type: "pfx",
  expired: false,
  itemId: "itm-77F153C0-1",
}

export const sellerKey = {
  disk: "/Volumes/DSKEYS/",
  path: "",
  name: "DS3110536560001 INTOP PLANET",
  alias:
    "cn=mirxodjayev davron mirafzalovich,name=davron,surname=mirxodjayev,l=olmazor tumani,st=toshkent shahri,c=uz,o=intop planet mchj,uid=476970476,1.2.860.3.16.1.2=32612790270038,ou=кўрсатилмаган,t=direktor,1.2.860.3.16.1.1=311053656,businesscategory=masʼuliyati cheklangan jamiyat,serialnumber=783de0e2,validfrom=2024.01.23 12:24:24,validto=2026.01.23 23:59:59",
  serialNumber: "783DE0E2",
  validFrom: "2024-01-23T07:24:24.000Z",
  validTo: "2026-01-23T18:59:59.000Z",
  CN: "MIRXODJAYEV DAVRON MIRAFZALOVICH",
  TIN: "311053656",
  UID: "476970476",
  PINFL: "32612790270038",
  O: "INTOP PLANET MCHJ",
  T: "DIREKTOR",
  type: "pfx",
  expired: false,
  itemId: "itm-783DE0E2-4",
}
export const buyerKey = {
  disk: "/Volumes/DSKEYS/",
  path: "",
  name: "DS4604519400007",
  alias:
    "cn=hasanov shaxrullo xayrullayevich,name=shaxrullo,surname=hasanov,l=olmazor tumani,st=toshkent shahri,c=uz,o=якка тартибдаги тадбиркор,uid=460451940,1.2.860.3.16.1.2=32005851070014,serialnumber=77fd34e5,validfrom=2023.04.26 16:12:31,validto=2025.04.26 23:59:59",
  serialNumber: "77FD34E5",
  validFrom: "2023-04-26T11:12:31.000Z",
  validTo: "2025-04-26T18:59:59.000Z",
  CN: "HASANOV SHAXRULLO XAYRULLAYEVICH",
  TIN: "460451940",
  UID: "460451940",
  PINFL: "32005851070014",
  O: "ЯККА ТАРТИБДАГИ ТАДБИРКОР",
  T: "",
  type: "pfx",
  expired: false,
  itemId: "itm-77FD34E5-2",
}

export const products = [
  {
    id: 1,
    name: "Washbasin",
    attributes: [
      {
        id: 1,
        name: "Width",
        value: "45 cm",
      },
      {
        id: 1,
        name: "Height",
        value: "45 cm",
      },
      {
        id: 1,
        name: "Depth",
        value: "45 cm",
      },
    ],
    slug: "home-123234",
    sku: "123234",
    price: 150000,
    discount: 30,
    in_stock: true,
    expected_delivery_date: "Jan 14, 2024",
    brand: {
      id: 1,
      slug: "apple",
      name: "Apple",
    },
    images: [
      {
        id: 1,
        link: "/images/product_img.svg",
        position: 1,
      },
      {
        id: 2,
        link: "/images/product_img.svg",
        position: 2,
      },
    ],
    carts: [
      {
        id: 1,
        quantity: 1,
      },
    ],
  },
]
export const order2 = [
  {
    id: 1,
    status: {
      key: 1,
      value: "rfp",
    },
    total_net: 37213800,
    total_price: 37213800,
    coupon_price: null,
    comment: "adfds",
    branch: "JKT Company 177 Kent St Brooklyn, NY 11222 United States",
    order_items: [
      {
        id: 1,
        product: {
          id: 93,
          name: "Iphone 15 red, 128",
          images: [
            {
              id: 56,
              link: "https://minio.vasit.uz/vasit/uploads/products/7h545SVWWGj4oVNPkFkPoP62jb8VIkvwq6ZKm9YF.jpg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=JqgfQP7w5XW234sda254fNbakGw6hgtFCwa%2F20240706%2Fuz-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240706T102454Z&X-Amz-SignedHeaders=host&X-Amz-Expires=600&X-Amz-Signature=c3fd7b85792d36a7213b319169eff4ce94a4307bd9cf2df85338ceaa37d0a8e2",
              position: 0,
            },
            {
              id: 57,
              link: "https://minio.vasit.uz/vasit/uploads/products/K4Cg5uFK9iAUVLP2VUxJqtMcNei1mPqrtsS1xaNj.jpg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=JqgfQP7w5XW234sda254fNbakGw6hgtFCwa%2F20240706%2Fuz-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240706T102454Z&X-Amz-SignedHeaders=host&X-Amz-Expires=600&X-Amz-Signature=6862c647328491de3f638d82c61607e05081d4e0dbaa4d337eef9e4885c198ef",
              position: 0,
            },
          ],
        },
        warehouse: null,
        quantity: 1,
        delivery_price: 250000,
        price: 12500000,
        discount: 0,
        total_net: 12500000,
        total_price: 12500000,
        shipping_address_id: null,
        billing_address_id: null,
        payment_method: 1,
        delivery_date: "2024-07-24 00:00:00",
        comment: "adfds",
      },
      {
        id: 2,
        product: {
          id: 14,
          name: "element2 41, red",
          images: [
            {
              id: 56,
              link: "https://minio.vasit.uz/vasit/uploads/products/7h545SVWWGj4oVNPkFkPoP62jb8VIkvwq6ZKm9YF.jpg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=JqgfQP7w5XW234sda254fNbakGw6hgtFCwa%2F20240706%2Fuz-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240706T102454Z&X-Amz-SignedHeaders=host&X-Amz-Expires=600&X-Amz-Signature=c3fd7b85792d36a7213b319169eff4ce94a4307bd9cf2df85338ceaa37d0a8e2",
              position: 0,
            },
          ],
        },
        warehouse: null,
        quantity: 1,
        delivery_price: 14276,
        price: 713800,
        discount: 0,
        total_net: 713800,
        total_price: 713800,
        shipping_address_id: null,
        billing_address_id: null,
        payment_method: 1,
        delivery_date: "2024-07-24 00:00:00",
        comment: "adfds",
      },
      {
        id: 3,
        product: {
          id: 92,
          name: "Iphone 15 white, 128",
          images: [
            {
              id: 54,
              link: "https://minio.vasit.uz/vasit/uploads/products/GKftWbrtIClk8OZ5MibOZMKJ4BwiMllYmVKnCS1I.jpg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=JqgfQP7w5XW234sda254fNbakGw6hgtFCwa%2F20240706%2Fuz-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240706T102454Z&X-Amz-SignedHeaders=host&X-Amz-Expires=600&X-Amz-Signature=6b8292e66b1b47fabbe91c0457983aa7db7735bf7cecd20edda8511c2082c437",
              position: 0,
            },
            {
              id: 55,
              link: "https://minio.vasit.uz/vasit/uploads/products/GVHWkEuAk4i4mk9tvvHFQDFh8YqCzRXVjGq4B7DI.jpg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=JqgfQP7w5XW234sda254fNbakGw6hgtFCwa%2F20240706%2Fuz-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240706T102454Z&X-Amz-SignedHeaders=host&X-Amz-Expires=600&X-Amz-Signature=cb98a4eadd51d97d09a956e4c124e12c838079d766117e7da23e7e39cd2abd6c",
              position: 0,
            },
          ],
        },
        warehouse: null,
        quantity: 2,
        delivery_price: 480000,
        price: 12000000,
        discount: 0,
        total_net: 24000000,
        total_price: 24000000,
        shipping_address_id: null,
        billing_address_id: null,
        payment_method: 1,
        delivery_date: "2024-07-24 00:00:00",
        comment: "adfds",
      },
    ],
  },
]


export const contract = {
  Clients: [
    {
      BranchCode: "",
      Host: "",
      Name: "",
      No: 0,
      Time: "",
      Tin: "",
    },
  ],
  Contract: {
    Clients: [
      {
        Account: "",
        Address: "",
        BankId: "",
        BankName: "",
        BranchCode: "",
        BranchName: "",
        Fax: "",
        Fio: "",
        FizTin: "",
        Mobile: "",
        Name: "",
        Oked: "",
        Position: "",
        Role: "",
        Status: true,
        Tin: "",
        WorkPhone: "",
      },
    ],
    ContractDoc: {
      ContractDate: "01.05.2024",
      ContractExpireDate: "31.12.2024",
      ContractName: "Oldi-sotdi shartnoma",
      ContractNo: "001",
      ContractPlace: "VASIT ONLINE",
    },
    ContractId: "",
    CreatedOperator: "",
    HasVat: true,
    Owner: {
      Account: "",
      Address: "",
      BankId: "",
      BankName: "",
      BranchCode: "",
      BranchName: "",
      Fax: "",
      Fio: "",
      FizTin: "",
      Mobile: "",
      Name: "",
      Oked: "",
      Position: "",
      Role: "",
      Status: true,
      Tin: "",
      WorkPhone: "",
    },
    Parts: [
      {
        Body: "",
        OrdNo: "",
        Title: "",
      },
    ],
    Products: [
      {
        BarCode: "",
        CatalogCode: "",
        CatalogName: "",
        Count: 0,
        DeliverySum: 0,
        DeliverySumWithVat: 0,
        MeasureId: "",
        Name: "",
        OrdNo: "",
        PackageCode: 0,
        PackageName: "",
        Summa: 0,
        TotalSum: 0,
        VatRate: 0,
        VatSum: 0,
        WithoutVat: true,
      },
    ],
  },
  CreatedAt: "",
  ErrMsg: "",
  FileLink: "",
  HasAccessBranch: 0,
  OrganizationId: "",
  PageStatus: "",
  PdfUrlRu: "",
  PdfUrlUz: "",
  PngRu: "",
  PngUz: "",
  Seller: {
    BranchCode: "",
    Host: "",
    Name: "",
    No: 0,
    Time: "",
    Tin: "",
  },
  Sign: "",
  Status: "",
  UpdatedAt: "",
}

export const columsSoliqProduct = [
  {
    title: "Tovarning identifikatsiya kodi*",
    dataIndex: "Name",
    key: "Name",
  },
  {
    title: "Tovar (xizmat) nomi*",
    dataIndex: "Name",
    key: "Name",
  },
  {
    title: "Tovar (xizmat)ning shtrix kodi",
    dataIndex: "BarCode",
    key: "BarCode",
  },
  {
    title: "O'lchov birligi	Soni",
    dataIndex: "MeasureId",
    key: "MeasureId",
  },
  {
    title: "Narxi*",
    dataIndex: "Summa",
    key: "Summa",
  },
  {
    title: "Yetkazish narxi*",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "QQS stavkasi, %",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "QQS umumiy qiymati*",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Yetkazish narxi QQS bilan*",
    dataIndex: "name",
    key: "name",
  },
]

export const attributes = {
  data: {
    id: 7,
    name: "trousers",
    attributes: [
      {
        id: 1,
        name: "size",
        units: [
          {
            id: 3,
            name: "Unit Franecki-Streich",
          },
          {
            id: 4,
            name: "Unit Cruickshank Group",
          },
        ],
        is_required: true,
        type: {
          key: 1,
          value: "color",
        },
        characteristics: [
          {
            id: 1,
            name: "white",
            code: "#FFFFFF",
          },
          {
            id: 7,
            name: "256",
            code: null,
          },
          {
            id: 6,
            name: "512",
            code: null,
          },
          {
            id: 5,
            name: "42",
            code: null,
          },
          {
            id: 4,
            name: "41",
            code: null,
          },
          {
            id: 2,
            name: "red",
            code: "#FF0000",
          },
        ],
      },
      {
        id: 2,
        name: "color",
        units: [],
        is_required: true,
        type: {
          key: 2,
          value: "string",
        },
        characteristics: [
          {
            id: 2,
            name: "red",
            code: "#FF0000",
          },
          {
            id: 3,
            name: "green",
            code: "#008000",
          },
          {
            id: 2,
            name: "red",
            code: "#FF0000",
          },
          {
            id: 1,
            name: "white",
            code: "#FFFFFF",
          },
        ],
      },
      {
        id: 3,
        name: "ram",
        units: [
          {
            id: 2,
            name: "Unit Stoltenberg-Greenholt",
          },
        ],
        is_required: true,
        type: {
          key: 3,
          value: "number",
        },
        characteristics: [
          {
            id: 8,
            name: "128",
            code: null,
          },
        ],
      },
    ],
  },
}
export const factura = {
  "Factura": {
      "Version": 1,
      "FacturaType": 0,
      "SingleSidedType": 0,
      "HasMarking": false,
      "FacturaId": "66332e8be6dc5ca6da8320e7",
      "FacturaDoc": {
          "FacturaNo": "TEST",
          "FacturaDate": "2024-05-01"
      },
      "OldFacturaDoc": {
          "OldFacturaId": "",
          "OldFacturaNo": "",
          "OldFacturaDate": ""
      },
      "ContractDoc": {
          "ContractNo": "TEST",
          "ContractDate": "2024-05-01"
      },
      "FacturaEmpowermentDoc": {
          "EmpowermentNo": "",
          "EmpowermentDateOfIssue": "",
          "AgentFacturaId": "66332e8be6dc5ca6da8320e8",
          "AgentFio": "",
          "AgentPinfl": ""
      },
      "ItemReleasedDoc": {
          "ItemReleasedTin": null,
          "ItemReleasedFio": "",
          "ItemReleasedPinfl": ""
      },
      "SellerTin": "311053656",
      "BuyerTin": "310296133",
      "Seller": {
          "Name": "\"INTOP PLANET\" MAS'ULIYATI CHEKLANGAN JAMIYAT",
          "Account": "20208000107006469001",
          "BankId": "01071",
          "Address": "Ziyokor MFY, Universitet ko'chasi, 2/1-uy  ",
          "Mobile": "",
          "WorkPhone": "",
          "Oked": "62090",
          "DistrictId": "",
          "Director": "MIRXODJAYEV DAVRON MIRAFZALOVICH",
          "Accountant": "",
          "VatRegCode": null,
          "VatRegStatus": null,
          "BranchCode": "",
          "BranchName": ""
      },
      "Buyer": {
          "Name": "\"PIXEL TOYS\" MAS'ULIYATI CHEKLANGAN JAMIYAT",
          "Account": "20208000905622923001",
          "BankId": "01033",
          "Address": "Qoratosh MFY, Koratosh mavzesi, 11а-uy  ",
          "Mobile": "",
          "WorkPhone": "",
          "Oked": "46900",
          "DistrictId": "",
          "Director": "XUSANOV SALOXIDDIN TO‘YCHIMUROD O‘G‘LI",
          "Accountant": "",
          "VatRegCode": "326090214452",
          "VatRegStatus": 20,
          "BranchCode": "",
          "BranchName": ""
      },
      "ForeignCompany": {
          "CountryId": "",
          "Name": "",
          "Address": "",
          "Bank": "",
          "Account": ""
      },
      "ProductList": {
          "FacturaProductId": "66332e8be6dc5ca6da8320e9",
          "Tin": "311053656",
          "HasExcise": false,
          "HasVat": true,
          "HasCommittent": false,
          "HasLgota": false,
          "HasMedical": false,
          "HasCustom": false,
          "HideReportCommittent": false,
          "Products": [
              {
                  "OrdNo": 1,
                  "CommittentName": "",
                  "CommittentTin": "",
                  "CommittentVatRegCode": "",
                  "CatalogCode": "01517001001003004",
                  "CatalogName": "Ўсимлик маргарини Masland Қаттиқ маргарин ёғлилиги 82%, (Каширланган фольга) 200 г.",
                  "BarCode": "",
                  "Name": "TEST",
                  "Serial": "",
                  "MeasureId": "",
                  "BaseSumma": 0,
                  "ProfitRate": 0,
                  "Count": 123.54,
                  "Summa": 9504.12,
                  "TotalSum": 0,
                  "DeliverySum": 0,
                  "ExciseRate": 0,
                  "ExciseSum": 0,
                  "VatRate": 12,
                  "VatSum": 0,
                  "DeliverySumWithVat": 0,
                  "WithoutVat": false,
                  "PackageCode": "1509126",
                  "PackageName": "шт. (упаковка) 200 грамм",
                  "CommittentVatRegStatus": null,
                  "Origin": 4,
                  "Marks": null,
                  "ExchangeInfo": null,
                  "LgotaId": null,
                  "LgotaName": null,
                  "LgotaVatSum": 0,
                  "LgotaType": null,
                  "WarehouseId": null,
                  "WarehouseName": null
              }
          ]
      },
      "IncomeType": 0,
      "LotId": "",
      "ContractId": "",
      "WaybillLocalIds": [],
      "WaybillIds": [],
      "FacturaInvestmentObjectDoc": {
          "ObjectId": "",
          "ObjectName": ""
      },
      "Notes": "",
      "CreatedOperator": "",
      "HasRent": false,
      "FacturaRentDoc": null
  },
  "Status": "reject",
  "PageStatus": "rad_etilgan",
  "CreatedAt": "2024-05-02 11:11:33",
  "UpdatedAt": "2024-05-02 11:55:36",
  "Notes": "note",
  "GNK": null,
  "Seller": {
      "Name": "MIRXODJAYEV DAVRON MIRAFZALOVICH",
      "Time": "02.05.2024 11:11:33",
      "No": 2017321186,
      "Tin": "476970476",
      "Host": "soliqservis.uz",
      "Ip": "84.54.70.19"
  },
  "Buyer": {
      "Name": "XUSANOV SALOXIDDIN TO‘YCHIMUROD O‘G‘LI",
      "Time": "02.05.2024 11:55:35",
      "No": 2012304320,
      "Tin": "310296133",
      "Host": "",
      "Ip": ""
  },
  "ErrMsg": "",
  "OrganizationId": "941ba6b5-625e-4520-9406-c58dadeac59f",
  "Internal": "",
  "Sign": "",
  "HasApp": false,
  "ActTransfer": "",
  "ChargeCalculation": "",
  "HasAccessBranch": 0,
  "OtherDocId": "",
  "FileLink": ""
}
export const waybill = {
        "Waybill": {
            "WaybillLocalId": "669fa726a6f505439ffd07e3",
            "DeliveryType": 2,
            "DeliveryCost": 1,
            "TotalDistance": 1,
            "TotalDeliveryCost": 1,
            "HasCommittent": true,
            "WaybillDoc": {
                "WaybillNo": "2",
                "WaybillDate": "2024-07-23"
            },
            "ContractDoc": {
                "ContractNo": "2",
                "ContractDate": "2024-07-23"
            },
            "Consignor": {
                "TinOrPinfl": "311053656",
                "Name": "INTOP PLANET MCHJ",
                "BranchCode": "",
                "BranchName": ""
            },
            "Consignee": {
                "TinOrPinfl": "32005851070014",
                "Name": "HASANOV SHAXRULLO XAYRULLAYEVICH",
                "BranchCode": "",
                "BranchName": ""
            },
            "FreightForwarder": {
                "TinOrPinfl": "304410214",
                "Name": "\"EXPRESS MAIL UNIVERSAL\" MAS'ULIYATI CHEKLANGAN JAMIYAT",
                "BranchCode": "",
                "BranchName": ""
            },
            "Carrier": {
                "TinOrPinfl": "304410214",
                "Name": "\"EXPRESS MAIL UNIVERSAL\" MAS'ULIYATI CHEKLANGAN JAMIYAT",
                "BranchCode": "",
                "BranchName": ""
            },
            "Client": {
                "ContractNo": "2",
                "ContractDate": "2024-07-23",
                "TinOrPinfl": "311053656",
                "Name": "INTOP PLANET MCHJ",
                "BranchCode": "",
                "BranchName": ""
            },
            "Payer": null,
            "ResponsiblePerson": {
                "Pinfl": "50408015310036",
                "FullName": "SODIQOV NODIRBEK RAXMIDDIN O‘G‘LI"
            },
            "TransportType": 1,
            "Roadway": {
                "Driver": {
                    "Pinfl": "30611710170049",
                    "FullName": "KADIROV XAYRULLA"
                },
                "Truck": {
                    "RegNo": "01 C 389 NA",
                    "Model": "VAZ 21061"
                },
                "Trailer": null,
                "Carriages": null,
                "ProductGroups": [
                    {
                        "LoadingPoint": {
                            "DistrictCode": 10,
                            "DistrictName": "Uchtepa tumani",
                            "Latitude": 69.248385,
                            "Longitude": 41.316427,
                            "MahallaId": 0,
                            "MahallaName": "",
                            "Address": "string",
                            "RegionId": 26,
                            "RegionName": "Toshkent shahri"
                        },
                        "LoadingTrustee": null,
                        "UnloadingPoint": {
                            "DistrictCode": 11,
                            "DistrictName": "Bektemir tumani",
                            "Latitude": 69.248385,
                            "Longitude": 41.316427,
                            "MahallaId": 0,
                            "MahallaName": "",
                            "Address": "string",
                            "RegionId": 26,
                            "RegionName": "Toshkent shahri"
                        },
                        "UnloadingTrustee": null,
                        "UnloadingEmpowerment": null,
                        "ProductInfo": {
                            "TotalDeliverySum": 12000000,
                            "TotalWeightBrutto": 1,
                            "Products": [
                                {
                                    "OrdNo": 1,
                                    "CommittentTinOrPinfl": "310296133",
                                    "CommittentName": "\"PIXEL TOYS\" MAS'ULIYATI CHEKLANGAN JAMIYAT",
                                    "ProductName": "Iphone 15 32, white, 8",
                                    "CatalogCode": "01517001001003004",
                                    "CatalogName": "Ўсимлик маргарини Masland Қаттиқ маргарин ёғлилиги 82%, (Каширланган фольга) 200 г.",
                                    "PackageCode": "1509126",
                                    "PackageName": "шт. (упаковка) 200 грамм",
                                    "Amount": 1,
                                    "Price": 12000000,
                                    "DeliverySum": 12000000,
                                    "WeightBrutto": 1,
                                    "WeightNetto": 1
                                }
                            ]
                        }
                    }
                ],
                "OtherCarOwners": null
            },
            "Airway": null,
            "Railway": null,
            "Shipway": null,
            "WaybillLocalType": 0,
            "WaybillLocalTypeName": "",
            "OldWaybillDoc": null,
            "SingleSidedType": 0
        },
        "Status": "consignor_sent",
        "Sign": "",
        "PageStatus": "",
        "CreatedAt": "2024-07-23 17:50:56",
        "UpdatedAt": "2024-07-23 17:50:56",
        "Consignor": [
            {
                "Name": "MIRXODJAYEV DAVRON MIRAFZALOVICH",
                "Time": "23.07.2024 17:50:56",
                "No": 2017321186,
                "Tin": "32612790270038",
                "BranchCode": "",
                "Host": "",
                "Ip": "84.54.70.247",
                "Status": "consignor_sent"
            }
        ],
        "Consignee": [
            {
                "Name": "",
                "Time": "",
                "No": 0,
                "Tin": "",
                "BranchCode": "",
                "Host": "",
                "Ip": "",
                "Status": ""
            }
        ],
        "Responsibleperson": [
            {
                "Name": "",
                "Time": "",
                "No": 0,
                "Tin": "",
                "BranchCode": "",
                "Host": "",
                "Ip": "",
                "Status": ""
            },
            {
                "Name": "",
                "Time": "",
                "No": 0,
                "Tin": "",
                "BranchCode": "",
                "Host": "",
                "Ip": "",
                "Status": ""
            }
        ],
        "GNK": null,
        "OrganizationId": "",
        "ErrMsg": ""
}