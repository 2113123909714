import axios_init from "./axios_init"

export const requests = {
  Soliq: {
    getInfo: (inn) =>
      axios_init.get(`np1/bytin/get-accounts?tinOrPinfl=${inn}`),
    contract: (data) => axios_init.post("/contract", data),
  },
  Auth: {
    login: (data) => axios_init.post(`/admin/auth/login`, data),
  },
  Document: {
    create: (data) => axios_init.post(`/admin/contract/create`, data),
  },
  Element: {
    categories: (data) => axios_init.post(`/admin/element/categories`, data),
    countries: (data) => axios_init.post(`/admin/element/countries`, data),
    warehouses: () => axios_init.post(`/admin/element/warehouses`),
    units: () => axios_init.post(`/admin/element/units`),
    attributes: (data) =>
      axios_init.post(`/admin/element/categories/show`, data),
    getList: (data) => axios_init.post(`/admin/element/index`, data),
    show: (data) => axios_init.post(`/admin/element/show`, data),
    statuses: (data) => axios_init.post(`/admin/element/statuses`, data),
    userList: (data) => axios_init.post(`/admin/element/user-lists`, data),
    preview: (data) => axios_init.post(`/admin/element/preview`, data),
    create: (data) => axios_init.post(`/admin/element/create`, data),
    update: (data) => axios_init.post(`/admin/element/update`, data),
    delete: (data) => axios_init.post(`/admin/element/remove`, data),
    restore: (data) => axios_init.post(`/admin/element/restore`, data),
  },
  Categories: {
    getAllList: (data) => axios_init.post(`/admin/category/all-category`, data),
    getList: (data) => axios_init.post(`/admin/category/index`, data),
    show: (data) => axios_init.post(`/admin/category/show`, data),
    create: (data) => axios_init.post(`/admin/category/create`, data),
    update: (data) => axios_init.post(`/admin/category/update`, data),
    delete: (data) => axios_init.post(`/admin/category/remove`, data),
    restore: (data) => axios_init.post(`/admin/category/restore`, data),
  },
  Unit: {
    getAllList: (data) => axios_init.post(`/admin/unit/all-unit`, data),
    getList: (data) => axios_init.post(`/admin/unit/index`, data),
    create: (data) => axios_init.post(`/admin/unit/create`, data),
    show: (data) => axios_init.post(`/admin/unit/show`, data),
    update: (data) => axios_init.post(`/admin/unit/update`, data),
    delete: (data) => axios_init.post(`/admin/unit/remove`, data),
    restore: (data) => axios_init.post(`/admin/unit/restore`, data),
  },

  Attribute: {
    getAllList: (data) =>
      axios_init.post(`/admin/attribute/all-attribute`, data),
    getList: (data) => axios_init.post(`/admin/attribute/index`, data),
    getTypes: (data) => axios_init.post(`/admin/attribute/types`, data),
    create: (data) => axios_init.post(`/admin/attribute/create`, data),
    update: (data) => axios_init.post(`/admin/attribute/update`, data),
    delete: (data) => axios_init.post(`/admin/attribute/remove`, data),
    restore: (data) => axios_init.post(`/admin/attribute/restore`, data),
    show: (data) => axios_init.post(`/admin/attribute/show`, data),
  },
  NewsLetter: {
    getList: (data) => axios_init.post(`/admin/newsletter/index`, data),
    create: (data) => axios_init.post(`/admin/newsletter/create`, data),
    update: (data) => axios_init.post(`/admin/newsletter/update`, data),
    delete: (data) => axios_init.post(`/admin/newsletter/remove`, data),
    restore: (data) => axios_init.post(`/admin/newsletter/restore`, data),
  },
  Contract: {
    getList: (data) => axios_init.post(`/admin/contract/index`, data),
    create: (data) => axios_init.post(`/admin/contract/create`, data),
    update: (data) => axios_init.post(`/admin/contract/update`, data),
    delete: (data) => axios_init.post(`/admin/contract/remove`, data),
    restore: (data) => axios_init.post(`/admin/contract/restore`, data),
  },
  Uploads: {
    getList: (data) =>
      axios_init.get(`/upload/index?page=${data.page}&limit=${data.limit}`),
    create: (data) => axios_init.post(`/upload/create`, data),
  },
  Language: {
    getList: (data) => axios_init.post(`/admin/language/index`, data),
    create: (data) => axios_init.post(`/admin/language/create`, data),
    update: (data) => axios_init.post(`/admin/language/update`, data),
    delete: (data) => axios_init.post(`/admin/language/remove`, data),
    show: (data) => axios_init.post(`/admin/language/show`, data),
  },
  Characteristic: {
    getAllList: (data) =>
      axios_init.post(`/admin/characteristic/all-characteristic`, data),
    getList: (data) => axios_init.post(`/admin/characteristic/index`, data),
    create: (data) => axios_init.post(`/admin/characteristic/create`, data),
    update: (data) => axios_init.post(`/admin/characteristic/update`, data),
    delete: (data) => axios_init.post(`/admin/characteristic/remove`, data),
    restore: (data) => axios_init.post(`/admin/characteristic/restore`, data),
    show: (data) => axios_init.post(`/admin/characteristic/show`, data),
  },
  Permission: {
    getList: (data) => axios_init.post(`/admin/permission/index`, data),
    create: (data) => axios_init.post(`/admin/permission/create`, data),
    update: (data) => axios_init.post(`/admin/permission/update`, data),
    delete: (data) => axios_init.post(`/admin/permission/remove`, data),
    restore: (data) => axios_init.post(`/admin/permission/restore`, data),
    show: (data) => axios_init.post(`/admin/permission/show`, data),
  },
  Role: {
    getList: (data) => axios_init.post(`/admin/role/index`, data),
    create: (data) => axios_init.post(`/admin/role/create`, data),
    update: (data) => axios_init.post(`/admin/role/update`, data),
    delete: (data) => axios_init.post(`/admin/role/remove`, data),
    restore: (data) => axios_init.post(`/admin/role/restore`, data),
    show: (data) => axios_init.post(`/admin/role/show`, data),
  },
  PermissionGroup: {
    getAllActive: (data) =>
      axios_init.post(`/admin/permission-group/all-active`, data),
    getList: (data) => axios_init.post(`/admin/permission-group/index`, data),
    create: (data) => axios_init.post(`/admin/permission-group/create`, data),
    update: (data) => axios_init.post(`/admin/permission-group/update`, data),
    delete: (data) => axios_init.post(`/admin/permission-group/remove`, data),
    restore: (data) => axios_init.post(`/admin/permission-group/restore`, data),
    show: (data) => axios_init.post(`/admin/permission-group/show`, data),
  },
  DevOffs: {
    getList: (data) => axios_init.post(`/admin/day-offs/index`, data),
    create: (data) => axios_init.post(`/admin/day-offs/create`, data),
    update: (data) => axios_init.post(`/admin/day-offs/update`, data),
    delete: (data) => axios_init.post(`/admin/day-offs/remove`, data),
    show: (data) => axios_init.post(`/admin/day-offs/show`, data),
    typeList: (data) => axios_init.post(`/admin/day-offs/type-list`, data),
  },
  Staff: {
    getList: (data) => axios_init.post(`/admin/staff/index`, data),
    create: (data) => axios_init.post(`/admin/staff/create`, data),
    update: (data) => axios_init.post(`/admin/staff/update`, data),
    delete: (data) => axios_init.post(`/admin/staff/remove`, data),
    restore: (data) => axios_init.post(`/admin/staff/restore`, data),
    show: (data) => axios_init.post(`/admin/staff/show`, data),
  },
  Banner: {
    getList: (data) => axios_init.post(`/admin/banner/index`, data),
    create: (data) => axios_init.post(`/admin/banner/create`, data),
    update: (data) => axios_init.post(`/admin/banner/update`, data),
    delete: (data) => axios_init.post(`/admin/banner/remove`, data),
    restore: (data) => axios_init.post(`/admin/banner/restore`, data),
    show: (data) => axios_init.post(`/admin/banner/show`, data),
    typeList: (data) => axios_init.post(`/admin/banner/type-list`, data),
  },
  Company: {
    getList: (data) => axios_init.post(`/admin/company/index`, data),
    update: (data) => axios_init.post(`/admin/company/update`, data),
    show: (data) => axios_init.post(`/admin/company/show`, data),
    delete: (data) => axios_init.post(`/admin/company/delete`, data),
    restore: (data) => axios_init.post(`/admin/company/restore`, data),
  },

  City: {
    getList: (data) => axios_init.post(`/admin/city/index`, data),
    types: (data) => axios_init.post(`/admin/city/types`, data),

    create: (data) => axios_init.post(`/admin/city/create`, data),
    update: (data) => axios_init.post(`/admin/city/update`, data),
    delete: (data) => axios_init.post(`/admin/city/remove`, data),
    restore: (data) => axios_init.post(`/admin/role/restore`, data),
    show: (data) => axios_init.post(`/admin/city/show`, data),
  },

  Order: {
    getList: (data) => axios_init.post(`/admin/order/index`, data),
    statusUpdate: (data) => axios_init.post(`/admin/order/update`, data),
    show: (data) => axios_init.post(`/admin/order/show`, data),
  },
  DeliveryPayment: {
    getList: (data) => axios_init.post(`/admin/delivery-payment/index`, data),
    create: (data) => axios_init.post(`/admin/delivery-payment/create`, data),
    update: (data) => axios_init.post(`/admin/delivery-payment/update`, data),
    delete: (data) => axios_init.post(`/admin/delivery-payment/remove`, data),
    restore: (data) => axios_init.post(`/admin/delivery-payment/restore`, data),
    show: (data) => axios_init.post(`/admin/delivery-payment/show`, data),
  },
  Cities: {
    city: (data) => axios_init.post(`/cities`, data),
  },
}
