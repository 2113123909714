import React, { useState } from "react"
import { useHistory, Link, useLocation } from "react-router-dom"

import { Layout, Menu, message, Popconfirm } from "antd"
import routes from "../constants/router"
import SubMenu from "antd/es/menu/SubMenu"
import IconFinder from "../constants/icons"
import { LogOutIcon } from "../constants/icons/LogOutIcon"
import { getPermissionsArray } from "../utils"
const { Header, Sider, Content } = Layout
function MainLayout({ children }) {
  const userData = JSON.parse(localStorage.getItem("userData"))
  const history = useHistory()
  const location = useLocation()
  const [collapsed, setCollapsed] = useState(false)

  const confirm = (e) => {
    localStorage.removeItem("token")
    history.push("/login")
    message.success("Click on Yes")
  }
  const cancel = (e) => {
    console.log(e)
    // message.error("Click on No")
  }
  function menuGenerator(routes) {
    var renderMenu = permissionsRoutes(routes)
    return renderMenu?.map((e, i) => {
      if (e?.children && e?.children.length && !e?.noSub) {
        return (
          <SubMenu
            key={e.meta.title}
            icon={IconFinder(e?.meta?.icon)}
            title={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {e?.meta?.title}
              </div>
            }
          >
            {permissionsRoutes(e?.children).map((item, key) => (
              <Menu.Item key={item.path}>
                <Link to={item.path}>{item.meta.title}</Link>
              </Menu.Item>
            ))}
          </SubMenu>
        )
      } else {
        return (
          <Menu.Item key={e?.path} icon={IconFinder(e?.meta?.icon)}>
            <Link to={e?.path}>{e?.meta?.title}</Link>
          </Menu.Item>
        )
      }
    })
  }

  const permissionsRoutes = (routes) => {
    const permission = getPermissionsArray(userData?.roles)
    const generated = routes.filter(
      (item) =>
        permission.find((i) => i.key === item.meta.permission) ||
        item?.children?.filter((item) =>
          permission?.find((i) => i.key === item?.meta?.permission)
        )?.length
    )
    return generated
  }

  return (
    <Layout style={{ height: "100vh", backgroundColor: "#fff" }}>
      <Sider
        trigger={null}
        style={{
          padding: 0,
          background: "#fff",
        }}
        collapsible
        collapsed={collapsed}
      >
        <div className="flex p-5 gap-4">
          <img
            src="/images/avatar.png"
            className="object-contain"
            alt="logo"
            width={45}
            height={45}
          />
          <div>
            <p className="text-lg font-medium">Hi, Admin</p>
            <p className="text-[#9898A6] text-sm font-medium">admin@vasit.uz</p>
          </div>
        </div>
        {/* <Menu
          theme="light"
          mode="inline"
          defaultSelectedKeys={["/"]}
          selectedKeys={location.pathname}
          items={routes.map((item) => ({
            ...item,
            key: item.path,
            icon: IconFinder(item.meta.icon),
            label: item.meta.title,
            to: item.path,
            children: <Link to={item.path}>{item.meta.title}</Link>,
          }))}
        /> */}
        <Menu
          theme="light"
          mode="inline"
          style={{
            overflowY: "auto",
            maxHeight: "90vh",
          }}
          //   onOpenChange={onOpenChange}
          //   openKeys={openKeys}
          //   defaultSelectedKeys={[location.pathname.split("/")[1]]}
          selectedKeys={[location.pathname]}
        >
          {menuGenerator(routes)}
        </Menu>
      </Sider>
      <Layout>
        <Header
          style={{
            padding: 0,
            backgroundColor: "#F5F5FC",
            borderBottom: "1px solid #DDDDEF",
          }}
        >
          <div className="w-full h-full flex px-[30px] justify-between items-center">
            <img
              // onClick={() => setCollapsed(!collapsed)}
              alt=""
              src="/logo.svg"
              className="px-[15px]"
            />

            <Popconfirm
              title="Log out"
              description="Are you sure about this task?"
              onConfirm={confirm}
              onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <button className="hover:bg-[#9898A6]/10 px-3 py-1 rounded-lg text-[#9898A6] font-medium text-base flex gap-2 items-center">
                <span>Log out</span>
                <LogOutIcon />
              </button>
            </Popconfirm>
          </div>
        </Header>
        <Content
          style={{
            margin: "30px 16px",
            minHeight: 280,
            overflowY: "auto",
            // background: colorBgContainer,
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  )
}

export default MainLayout
