import axios from "axios"
import { notification } from "antd"

const token = localStorage.getItem("token")
function alert(title, des) {
  notification.error({
    message: title,
    description: des || "",
  })
}
function Redirect(msg, hooks) {
  alert(msg)
  localStorage.removeItem("token")
  window.location.replace("/login")
}

function ErrorHandler(error, hooks) {
  if (error.message.startsWith("timeout")) {
    alert("Time Out", "Please check your internet!")
  }
  if (error.response) {
    // debugger
    let _error = error.response
    switch (_error.status) {
      case 400:
        alert(_error?.data?.message)
        break
      case 401:
        Redirect("Unauthorazed", hooks)
        break
      case 403:
        alert("Forbidden")
        break
      case 404:
        alert("Not Found")
        break
      case 413:
        alert("Fayl xato kiritildi yoki hajmi katta!")
        break
      case 500:
        alert("Server Error!")
        break
      default:
        alert(_error?.data?.message)
        break
    }
  }
}

const init = {
  request(method, url, params, data, hooks, formdata = false) {
    const baseURL = (process.env.BASE_URL || "https://api.vasit.uz") + "/api/v1"
    let config = {
      baseURL: baseURL,
      timeout: 30000,
      url: url,
      method: method,
      onUploadProgress: function (e) {
        Math.round((e.loaded * 100) / e.total)
      },
    }
    if (token) {
      config.headers = {
        Authorization: `Bearer ${token}`,
      }
    }
    if (data) config.data = data

    if (params) config.params = params

    let result = axios(config)

    return new Promise((resolve, reject) => {
      result
        .then((res) => {
          resolve(res.data)
        })
        .catch((error) => {
          ErrorHandler(error, hooks)
          // reject(error)
        })
    })
    // axios(config).then(res => {
    //     return Promise.resolve(res.data)
    // })
    // .catch(error => {
    //     ErrorHandler(error)
    // })
    // return axios(config)
  },
  get(url, params, hooks) {
    return this.request("GET", url, params, undefined, hooks)
  },
  post(url, data, params, hooks) {
    return this.request("POST", url, params, data, hooks)
  },
  put(url, data, params, hooks) {
    return this.request("PUT", url, params, data, hooks)
  },
  remove(url, data, params, hooks) {
    return this.request("DELETE", url, params, undefined, hooks)
  },
}

export default init
