import { DashboardFilled, ShoppingOutlined, FileTextFilled } from "@ant-design/icons"
import { SettingsIcon } from "./icons/SettingsIcon"
import { RFPIcon } from "./icons/RFPIcon"
import { ProductIcon } from "./icons/ProductIcon"

function IconFinder(icon) {
  switch (icon) {
    case "DashboardIcon":
      return <DashboardFilled size={25} className="!text-[#4060FF]" />
    case "ShoppingOutlined":
      return <RFPIcon />
    case "SettingOutlined":
      return <SettingsIcon />
    case "ProductIcon":
      return <ProductIcon />
      case "FileOutlined":
        return <FileTextFilled style={{color: "#4060FF", fontSize: "25px"}}/>
    default:
      break
  }
}
export default IconFinder
