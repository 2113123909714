import React, { Suspense, useEffect, useState } from "react"
import { Redirect, Route, Switch, withRouter } from "react-router-dom"
import routes from "../constants/router"
import { Spin } from "antd"
import { permission } from "../constants"
// import { useSelector } from "react-redux"

const Routes = () => {
  const [publicRouteList, setPublicRouteList] = useState([])
  const [isGenerated, setIsGenerated] = useState(false)

  useEffect(() => {
    generatedRoutes(routes)
  }, [])

  //   useEffect(() => {
  //     nprogress.done()
  //     return () => nprogress.start()
  //   })
  const permissionsRoutes = (routes) => {
    const generated = routes.filter(
      (item) =>
        permission?.find((i) => i.key === item?.meta?.permission) ||
        item?.children?.filter((item) =>
          permission?.find((i) => i.key === item?.meta?.permission)
        )?.length
    )
    return routes
  }
  const token = localStorage.getItem("token")
  function generatedRoutes(rout) {
    permissionsRoutes(rout).forEach((e, i) => {
      if (e.children && e.children.length) {
        setPublicRouteList((old) => [
          ...old,
          <AppRoute key={e} exact path={e.path} component={e.component} />,
        ])
        generatedRoutes(e.children)
      } else {
        setPublicRouteList((old) => [
          ...old,
          <AppRoute key={e} exact path={e.path} component={e.component} />,
        ])
        setIsGenerated(true)
      }
    })
  }

  const AppRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) => (
        <Suspense fallback={<>Loading....</>}>
          <Component {...props} />
        </Suspense>
      )}
    />
  )

  return (
    <>
      {isGenerated ? (
        <Switch>
          {token ? (
            // undefined

            publicRouteList
          ) : (
            <Redirect from="*" to="/login" />
          )}
        </Switch>
      ) : undefined}
    </>
  )
}

export default withRouter(Routes)
