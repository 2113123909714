import { lazy } from "react"
// ESLATMA PERMISSION BERILADIGAN JOYDA, RATHNING / DAN KEYINGI BIRINCHI QIYMATI PERMISION STRING ICHIDA BOLISHI SHART
const routes = [
  {
    path: "/",
    component: lazy(() => import("../pages/Dashboard")),
    meta: {
      title: "Dashboard",
      icon: "DashboardIcon",
      permission: "admin/order/index",
    },
  },
  {
    path: "/orders",
    component: lazy(() => import("../pages/Orders")),
    meta: {
      title: "RFP’s",
      icon: "ShoppingOutlined",
      permission: "admin/order/index",
    },
    noSub: true,
    children: [
      {
        path: "/orders/:id",
        component: lazy(() => import("../pages/Orders/Details")),
      },
      {
        path: "/order/waybill/:id",
        component: lazy(() => import("../pages/Orders/Documents/Waybill")),
      },
      {
        path: "/order/factura/:id",
        component: lazy(() => import("../pages/Orders/Documents/Factura")),
      },
      {
        path: "/order/document/waybill/:id",
        component: lazy(() => import("../pages/Orders/Waybill/PDF")),
      },
      {
        path: "/order/document/factura/:id",
        component: lazy(() => import("../pages/Orders/Factura/PDF")),
      },
    ],
  },
  {
    path: "/products",
    component: lazy(() => import("../pages/Products")),
    meta: {
      title: "Products",
      icon: "ProductIcon",
      permission: "admin/element/index",
    },
    noSub: true,

    children: [
      {
        path: "/products/:id",
        component: lazy(() => import("../pages/Products/Details")),
      },
      {
        path: "/preview/:id",
        component: lazy(() => import("../pages/Products/Preview")),
      },
    ],
  },
  {
    path: "/documents",
    component: lazy(() => import("../pages/Documents")),
    meta: {
      title: "Documents",
      icon: "FileOutlined",
      permission: "admin/contract/index",
    },
  },
  {
    path: "/category",
    component: lazy(() => import("../pages/Settings/Categories")),
    meta: {
      title: "Settings",
      icon: "SettingOutlined",
      permission: "",
    },
    children: [
      {
        path: "/category",
        component: lazy(() => import("../pages/Settings/Categories")),
        meta: {
          title: "Categories",
          icon: "",
          permission: "admin/category/index",
        },
        children: [
          {
            path: "/category/:id",
            component: lazy(() => import("../pages/Settings/Categories")),
            meta: {
              permission: "admin/category/index",
            },
          },
        ],
      },
      {
        path: "/unit",
        component: lazy(() => import("../pages/Settings/Unit")),
        meta: {
          title: "Unit",
          icon: "",
          permission: "admin/unit/index",
        },
        children: [
          {
            path: "/unit/:id",
            component: lazy(() => import("../pages/Settings/Unit")),
            meta: {
              permission: "admin/unit/index",
            },
          },
        ],
      },
      {
        path: "/attribute",
        component: lazy(() => import("../pages/Settings/Attribute")),
        meta: {
          title: "Attribute",
          icon: "",
          permission: "admin/attribute/index",
        },
        children: [
          {
            path: "/attribute/:id",
            component: lazy(() => import("../pages/Settings/Characteristic")),
            meta: {
              permission: "admin/characteristic/index",
            },
          },
        ],
      },
      {
        path: "/newsletter",
        component: lazy(() => import("../pages/Settings/NewsLetter")),
        meta: {
          title: "News Letter",
          icon: "",
          permission: "admin/newsletter/index",
        },
      },

      // {
      //   path: "/characteristic",
      //   component: lazy(() => import("../pages/Settings/Characteristic")),
      //   meta: {
      //     title: "Characteristic",
      //     icon: "",
      //     permission: "admin/characteristic/index",
      //   },
      // },
      {
        path: "/role",
        component: lazy(() => import("../pages/Settings/Role")),
        meta: {
          title: "Role",
          icon: "",
          permission: "admin/role/index",
        },
      },
      {
        path: "/permission-group",
        component: lazy(() => import("../pages/Settings/PermissionGroup")),
        meta: {
          title: "Permission Group",
          icon: "",
          permission: "admin/permission-group/index",
        },
      },
      // {
      //   path: "/permission",
      //   component: lazy(() => import("../pages/Settings/Permission")),
      //   meta: {
      //     title: "Permission",
      //     icon: "",
      //     permission: "admin/permission/index",
      //   },
      // },
      {
        path: "/day-offs",
        component: lazy(() => import("../pages/Settings/DayOffs")),
        meta: {
          title: "Day Offs",
          icon: "",
          permission: "admin/day-offs/index",
        },
      },
      {
        path: "/staff",
        component: lazy(() => import("../pages/Settings/Staff")),
        meta: {
          title: "Staff ",
          icon: "",
          permission: "admin/staff/index",
        },
      },
      {
        path: "/banner",
        component: lazy(() => import("../pages/Settings/Banner")),
        meta: {
          title: "Banner",
          icon: "",
          permission: "admin/banner/index",
        },
      },
      {
        path: "/company",
        component: lazy(() => import("../pages/Settings/Company")),
        meta: {
          title: "Company",
          icon: "",
          permission: "admin/company/index",
        },
      },
      {
        path: "/language",
        component: lazy(() => import("../pages/Settings/Language")),
        meta: {
          title: "Language",
          icon: "",
          permission: "admin/language/index",
        },
      },
      {
        path: "/city",
        component: lazy(() => import("../pages/Settings/City")),
        meta: {
          title: "City",
          icon: "",
          permission: "admin/city/index",
        },
        noSub: true,

        children: [
          {
            path: "/city/:id",
            component: lazy(() => import("../pages/Settings/City")),
          },
          {
            path: "/preview/:id",
            component: lazy(() => import("../pages/Products/Preview")),
          },
        ],
      },

      {
        path: "/delivery-payment",
        component: lazy(() => import("../pages/Settings/DeliveryPayment")),
        meta: {
          title: "Delivey Payment",
          icon: "",
          permission: "admin/delivery-payment/index",
        },
      },
    ],
  },
]

export default routes
