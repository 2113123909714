export const formatValue = (value = 0) =>
  value
    ?.toFixed()
    ?.toString()
    ?.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")
export function getPermissionsArray(data) {
  if (!Array.isArray(data)) return []

  return data.reduce((acc, item) => {
    if (item.permissions && Array.isArray(item.permissions)) {
      acc.push(...item.permissions)
    }
    return acc
  }, [])
}
